<template>
	<div class="mine page">
		<div class="movie-hall page">
            <section id="team" class="team mowpad relative">
                <div class="title">
                    <h1 data-v-3734890c="">CÔNG TY TNHH ĐẦU TƯ DỊCH VỤ DI TRÚ TOÀN CẦU</h1>
                </div>
                <div class="mowtainer">
                    <div class="mowgrid">
                        <div class="member">
                            <div class="image">
                                <img src="/img/bs1/a1.jpg" alt="">
                            </div></div><div class="member">
                                <div class="image">
                                    <img src="/img/bs1/a2.jpg" alt="">
                                </div>
                            </div>
                            <div class="member">
                                <div class="image">
                                    <img src="/img/bs1/a3.jpg" alt="">
                                </div></div><div class="member">
                                    <div class="image">
                                        <img src="/img/bs1/a4.jpg" alt="">
                                    </div>
                                </div>
								
                                <!-- <div class="image">
                                    <img src="/img/bs/b7.jpg" alt="" style="width: 345px;">
                                </div>
                                <br data-v-3734890c="">
                                <div class="image">
                                    <img src="/img/bs/b6.jpg" alt="" style="width: 345px;">
                                </div> 
                                <br data-v-3734890c="">
								<div class="image">
                                    <img src="/img/bs/b8.jpg" alt="" style="width: 345px;">
                                </div> -->
                                <br data-v-3734890c="">
                            </div>
                        </div>
						<div style="display: flex;flex-direction: column;margin-bottom: 28px;">
							<div  style="display: flex;">
										<img src="/img/bs1/q1.jpg" style="width: 100%;" alt="">
										
									</div>
									<div  style="display: flex;">
										<img src="/img/bs1/q2.jpg" style="width: 100%;" alt="">
										<!-- <img src="/img/bs1/2.jpg" style="width: 50%;" alt=""> -->
									</div>
									<!-- <div  style="display: flex;">
										<img src="/img/bs1/1.jpg" style="width: 100%;" alt="">
										<img src="/img/bs1/2.jpg" style="width: 50%;" alt=""> 
									</div> -->
									<div  style="display: flex;">
										<img src="/img/bs1/2.jpg" style="width: 100%;" alt="">
										<!-- <img src="/img/bs1/333.jpg" style="width: 50%;" alt=""> -->
									</div>
									<!-- <div  style="display: flex;">
										<img src="/img/bs1/5.jpg" style="width: 50%;" alt="">
										<img src="/img/bs1/6.jpg" style="width: 50%;" alt="">
									</div> -->
									
									<div  style="display: flex;">
										<img src="/img/bs1/7.jpg" style="width: 100%;" alt="">
									</div>
							</div>
                    </section>

								
                </div>

				
	
	</div>

</template>

<script>
	export default {
		data() {
			return {
				userInfo: {},
				menu_top: 40,
				isLoading: false,
			};
		},
		methods: {
			refresh() {
				this.isLoading = true;
				setTimeout(() => {
					this.isLoading = false;
					if (localStorage.getItem('token')) {
						this.$toast(this.$t("reservation.refresh"));
					} else {
						this.$router.push({
							path: '/Login'
						})
					}
				}, 500);
			},
			exit() {
				this.$toast(this.$t("my.finish_task"));
			},
			showSetting() {
				if (localStorage.getItem('token')) {
					this.$router.push({
						path: '/Setting'
					})
				} else {
					this.$router.push({
						path: '/Login'
					})
				}
			},
			toNotice() {
				if (localStorage.getItem('token')) {
					this.$router.push({
						path: '/Notice'
					})
				} else {
					this.$router.push({
						path: '/Login'
					})
				}
			},
			onRefresh() {
				setTimeout(() => {
					this.isLoading = false;
					if (localStorage.getItem('token')) {
						this.getUserInfo();
						this.$toast(this.$t("reservation.refresh"));
					} else {
						this.$router.push({
							path: '/Login'
						})
					}
				}, 500);
			},
			doLogin() {
				if (localStorage.getItem('token')) {
					this.$router.push({
						path: '/Infomation'
					});
				} else {
					this.$router.push({
						path: '/Login'
					})
				}
			},
			doPay() {
				this.$router.push({
					name: 'Recharge',
					params: {
						'balance': this.userInfo.money
					}
				})
			},
			doWithdrawal() {
				this.$http({
					method: 'get',
					url: 'user_get_bank'
				}).then(res => {
					if (res.data.is_bank) {
						this.$router.push("withdraw");
					} else {
						this.$router.push("Setbank");
						this.$toast.fail(this.$t("setting.set_bank"));
					}
				})
			},
			toService() {
				if (this.$store.getters.getBaseInfo.iskefu == 1) {
					this.$router.push("ServiceOnline");
				} else {
					this.$toast.fail(this.$t("setting.forbid"));
				}
			},
			getUserInfo() {
				this.$http({
					method: 'get',
					url: 'user_info'
				}).then(res => {
					if (res.code === 200) {
						this.userInfo = res.data;
						this.menu_top = 15;
						if (this.userInfo.status !== 1) {
							this.$toast(this.$t("video.account_out"));
							localStorage.clear()
							this.$router.push({
								path: '/Login'
							})
						}
					} else if (res.code === 401) {
						this.$toast(res.msg);
					}
				})
			},
		},
		created() {
			if (localStorage.getItem('token')) {
				this.getUserInfo();
			} else {
				this.userInfo.username = this.$t("setting.log_reg");
				this.userInfo.ip = this.$t("setting.more_service");
				this.userInfo.header_img = "img/mine/avatar.png";
			}
		}
	};
</script>

<style scoped>

    #team{
        /* font-family: Inter, sans-serif; */
        /* font-weight: 400; */
        color: #fff !important;
        padding: 30PX 0;
    }
    .title {
        margin-bottom: 30PX;
        text-align: center;
    }

    .title h1 {
        font-size: 46PX;
        font-family: Old Fenris, sans-serif;
        text-transform: uppercase;
        letter-spacing: 3PX;
        text-shadow: 0 6PX 8PX #000;
        font-size: 40PX;
        margin-bottom: 20PX;
    }

    .mowtainer {
    width: 90%;
    margin: 0 auto;
}
.team .mowgrid {
    grid-template-columns: repeat(auto-fill, minmax(130PX, 1fr));
    grid-gap: 40PX 30PX;
    display: grid;
}
.team .member .image {
    border-radius: 50%;
    border: 6PX ridge #bca05c;
    position: relative;
    z-index: 2;
    box-shadow: 0 0 15PX #000;
}
.team .member img {
    width: 100%;
    height: 159PX;
    border-radius: 50%;
    object-fit: cover;
    vertical-align: middle;
}
.team {
    background: #9fdfe9;
    background-image: url(/img/bs/2.webp);
    background-size: cover;
    z-index: 1;
}




	.page {
		position: absolute !important;
		top: 0;
		left: 0;
		right: 0;
		background-color: #f2f2f5;
	}
	.bg-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(hsla(0, 0%, 100%, 0), #500cfd);
	}
	.mine {
		position: relative;
		bottom: 10px;
		background: #f2f2f5;
	}

	.mine .wrapper {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}

	.nav-bar {
		background: none;
		/* background: linear-gradient(90deg, #f487e0,#988fba); */
	}

	.mine .header {
		background: none;
		/* background: linear-gradient(90deg, #f487e0,#988fba); */
		padding-bottom: 100px;
	}

	::v-deep .van-nav-bar__content {
		height: 100px;
	}

	::v-deep .van-hairline--bottom::after {
		border-bottom-width: 0px;
	}

	.mine .header .van-nav-bar .van-icon {
		font-size: 45px;
	}

	.mine .header .user-wrapper {
		display: flex;
		align-items: center;
		margin: 0px 40px 0px 40px;

	}

	.mine .user_img {
		height: 130px;
		width: 130px;
	}

	::v-deep .van-loading__spinner {
		height: 50px;
		width: 50px;
	}

	::v-deep .van-image__error-icon {
		font-size: 70px;
	}

	.mine .header .user-wrapper .login-content {
		flex: 1;
		margin-left: 30px;
	}

	.mine .header .user-wrapper .login-content .login-btn {
		display: inline-block;
		font-size: 40px;
		line-height: 0px;
		color: #fff;
	}

	.mine .header .user-wrapper .login-content .login-label {
		margin-top: -13px;
		font-size: 28px;
		color: hsla(0, 0%, 100%, .6);
	}

	.mine .page-bg {
		height: 500px;
		/* background: linear-gradient(90deg, #f487e0,#988fba); */
		background: linear-gradient(90deg, #500cfd, #9e9ce7);
		z-index: 0;
    background-size: cover;
	position: relative;
	}

	.mine .content {
		position: relative;
		padding: 10px 30px 30px;
		min-height: 500px;
		background-color: #f2f2f5;
	}

	::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
		color: #ffffff;
		font-size: 35px;
	}

	.mine .wrapper .content .finance {
		position: absolute;
		display: flex;
		align-items: center;
		top: -55px;
		left: 30px;
		right: 30px;
		height: 120px;
		background-color: #fff;
		border-radius: 15px;
		box-shadow: 0 1.5px 1px 0 #e4e4e7;
	}

	.mine .wrapper .content .finance .line {
		width: 3px;
		height: 40px;
		background-color: #ccc;
	}

	.mine .wrapper .content .finance .finance-item {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	.mine .wrapper .content .finance .finance-item .text {
		margin-left: 30px;
		font-size: 30px;
		color: #000;
		font-weight: 500;
	}

	.mine .wrapper .content .finance .finance-item .icon {
		font-size: 50px;
	}

	.mine .wrapper .content .menu {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		background-color: #fff;
		border-radius: 15px;
		box-shadow: 0 1.5px 1px 0 #e4e4e7;
	}

	.mine .wrapper .content .menu .menu-item {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 50%;
		height: 130px;
	}

	.mine .wrapper .content .menu .menu-item .menu-item-label {
		font-size: 30px;
		color: #868686;
		font-weight: 500;
	}

	.mine .wrapper .content .menu .menu-item .menu-item-icon {
		margin: 25px;
		width: 60px;
		height: 60px;
		-o-object-fit: contain;
		object-fit: contain;
	}

	.mine .wrapper .content .wallet {
		margin-top: 80px;
		padding: 0 30px;
		background-color: #fff;
		border-radius: 15px;
		box-shadow: 0 1.5px 1px 0 #e4e4e7;
	}

	.mine .wrapper .content .wallet .part-1 {
		display: flex;
		align-items: center;
		height: 100px;
	}

	.mine .wrapper .content .wallet .font-primary-color {
		color: #000;
	}

	.font-gray {
		color: #868686;
	}

	.mine .wrapper .content .wallet .part-2 {
		display: flex;
		align-items: center;
		height: 150px;
	}

	.mine .wrapper .content .wallet .part-2 .balance {
		flex: 1;
		font-size: 60px;
		color: #f487e0;
		font-weight: 700;
	}

	.mine .wrapper .content .wallet .van-hairline--bottom::after {
		border-bottom-width: 3px;
	}

	.mine .wrapper .content .wallet .part-2 .refresh-btn {
		margin-left: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 50px;
		font-size: 30px;
		border-radius: 50%;
		color: #ffffff;
		background-color: #e6c3a1;
	}
</style>